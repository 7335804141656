import React from 'react'
import Layout from '../components/layout-v2';
import Services from '../components/services';


const Page = () => (

  <Layout title="How Quantic can help ?">
    <section className="section-services">
      Our technology firm is here to help you and your business. From any engineering needs you might have to product development, data security, data appliances, infrastructure services or staffing needs for your upcoming challenges and dreams, we're here to help you achieve your dreams.
      <br/>
      <br/>

      <ul>
        <li>Provide a vendor-agnostic, application-centric assessment to kick off your public, private, or hybrid cloud project.</li>
        <li>Build or refine a cloud strategy based on current state, business needs, and budgets.</li>
        <li>Implement new platforms including SaaS, public cloud, private cloud, hosting options, and private infrastructure technology.</li>
        <li>Map workload alignments and application dependencies.</li>
        <li>Design an IT services operations strategy, including required in-house knowledge versus cost benefits of using managed services.</li>
        <li>Develop an optimized future state with financial benefit analysis.</li>
        <li>Migrate applications, technology, and data to the future state.</li>
        <li>Create operational and governance models for new platforms.</li>
      </ul>
      <Services/>
    </section>
  </Layout>
);

export default Page;
